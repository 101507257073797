<template>
  <span>
    <el-drawer :wrapperClosable="false" custom-class="room-add-room" :title="title || '添加房间'" :visible="drawer"
      :before-close="close" :direction="'rtl'" :size="350">
      <div class="add-room-body d-f">
        <div class="add-room-content">
          <div class="add-room-title require">房间</div>
          <el-input v-model="addRoom.roomNo" placeholder="请填写房间号">
            <template slot="prepend">{{
              addRoom.buildingName || checkedBuildName
            }}</template>
          </el-input>
          <div class="add-room-title require">床位</div>
          <el-input v-model="addRoom.bedNum" placeholder="请填写房间床位总数量">
          </el-input>

          <div class="add-room-title">男女属性</div>
          <!-- <el-input
            :disabled="roomSex!=2"
            v-model="
              ['男生宿舍', '女生宿舍', '男女混住'][
                addRoom.roomCharacter || roomSex
              ]
            "
            placeholder=""
          /> -->
          <el-select :disabled="roomSex != 2 ||
            (roomSex == 2 && addRoom.student && addRoom.student.length)
            " style="width: 100%" v-model="addRoom.roomCharacter">
            <el-option label="男生宿舍" value="0">男生宿舍</el-option>
            <el-option label="女生宿舍" value="1">女生宿舍</el-option>
          </el-select>
          <div class="add-room-title d-f ai-c jc-sb">
            <el-button v-if="addRoom.student && addRoom.student.length" @click="roomVisible = true">调换宿舍</el-button>
            <el-button @click="addStudent">添加学生</el-button>
            <!-- <span class="c-9 fs-12 c-p"><i class="el-icon-circle-plus-outline"> 新增</i></span> -->
          </div>
          <el-alert style="margin: 3px 0" v-for="(item, index) in addRoom.student" :key="item.id" type="success"
            :title="item.title" @close="$delete(addRoom.student, index)">
          </el-alert>
        </div>
        <div class="add-room-submit w-100_ d-f ai-c">
          <el-button type="primary" @click="addRoomFunc">保 存</el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </el-drawer>
    <room-add-student ref="addStudent" :ssex="addRoom.roomCharacter == 2 ? '' : addRoom.roomCharacter" :roomSex="roomSex"
      @addStudents="addStudents"></room-add-student>
    <el-dialog :visible="roomVisible" :before-close="changRoomCancel" title="所有学生调整到新房间" width="245px">
      <div>
        楼栋
      </div>
      <el-select v-model="newBuildValue" filterable placeholder="请选择">
        <el-option v-for="item in roomList.filter(it => [addRoom.buildingCharacter, '2'].includes(it.buildingCharacter))"
          :key="item.id" :label="item.buildingName" :value="item.id">
        </el-option>
      </el-select>
      <div style="margin-top: 10px;">房间号</div>
      <el-select v-model="newRoomValue" filterable placeholder="请选择">
        <el-option v-for="item in RoomOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changRoomCancel">取 消</el-button>
        <el-button type="primary" @click="changRoomSure">确 定</el-button>
      </span>
    </el-dialog>
  </span>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("room");
const {
  mapState: studentState,
  mapActions: studentAction,
  mapMutations: studentMutation,
} = createNamespacedHelpers("student");
import RoomAddStudent from "./roomAddStudent.vue";
export default {
  name: "room-body-add-room",
  components: { RoomAddStudent },
  data() {
    return {
      drawer: false, //  抽屉
      addRoom: { roomNo: "", bedNum: "" },
      loading: false,
      options: [],
      addStudentShow: false,
      studentQuery: "", // 搜索学生内容
      pageSize: 15,
      current: 1,
      roomVisible: false,
      storeStudentIds: [], //  用作记录修改时候存进来的学生id
      RoomOptions: [],
      newRoomValue: "",
      newBuildValue: ""
    };
  },
  methods: {
    ...mapActions(["searchStudentFux", "getRoomDataByParams", "changeRoomFunc"]),
    ...studentAction(["getStudentData"]),
    ...studentMutation(["setTableList"]),

    changRoomCancel() {
      this.newRoomValue = "";
      this.newBuildValue = ""
      this.roomVisible = false
    },
    changRoomSure() {
      if (this.RoomOptions.find(item => item.id == this.newRoomValue).existStudents) {
        this.$message.error('新宿舍有人，请先清空新宿舍再调换')
        return
      }
      if (!this.newRoomValue) {
        this.changRoomCancel()
      } else {
        this.changeRoomFunc({
          id: this.addRoom.id,
          exchangeRoomId: this.newRoomValue
        }).then(res => {
          this.changRoomCancel();
          this.close();
          this.$emit('query')
        })


      }

    },
    addStudents(batchStudent) {
      console.log("batchStudent", batchStudent);
      const student = [
        ...(this.addRoom.student || []),
        ...batchStudent
          .map((it) =>
            !(this.addRoom.student || []).some((y) => y.id == it.id)
              ? {
                ...it,
                title: it.studentName + `(${it.identityNo.slice(-6)})`,
                id: it.id,
              }
              : null
          )
          .filter(Boolean),
      ];
      this.$set(this.addRoom, "student", student);
      console.log(this.addRoom.student);
      this.addStudentShow = false;
    },
    showRoom(buildingId) {
      console.log(this.addRoom)
      this.getRoomDataByParams({
        pageNum: 1,
        pageSize: 150000,
        buildingId: buildingId
      }).then(res => {
        console.log(res)
        if (res.list && res.list.length) {
          this.RoomOptions = res.list.map(it => ({
            ...it,
            value: it.id,
            label: it.roomNo
          }))
        }
      })
    },
    close() {
      this.$emit("update:visible", false);
    },
    addRoomFunc() {
      //  保存信息
      const warning = { roomNo: "请输入房间号", bedNum: "请输入床位数" };
      const list = Object.keys(warning);
      let canIgo = true;
      for (let i = 0; i < list.length; i++) {
        if (!String(this.addRoom[list[i]])) {
          this.$message.error(warning[list[i]]);
          canIgo = false;
          break;
        }
      }
      if (this.addRoom.bedNum < 0) {
        this.$message.error("床位数不能为负数");
        return;
      }
      if (this.addRoom.bedNum < (this.addRoom.student?.length ?? 0)) {
        this.$message.error("学生数量不应该大于床位数量");
        return;
      }
      if (canIgo) {
        const params = { ...this.addRoom };
        params.roomNo =
          (params.buildingName || this.checkedBuildName) + params.roomNo;
        if (this.storeStudentIds.length == 0) {
          params.delStudentIds = "";
        } else {
          let studentids = (params.student || []).map((itt) => String(itt.id));
          for (let i = 0; i < this.storeStudentIds.length; i++) {
            const ids = String(this.storeStudentIds[i]);
            if (!studentids.includes(ids)) {
              params.delStudentIds
                ? params.delStudentIds.push(ids)
                : (params.delStudentIds = [ids]);
            }
          }
          let lsList = [...studentids];
          for (let i = 0; i < studentids.length; i++) {
            const ids = studentids[i];
            console.log(lsList, this.storeStudentIds, ids, studentids);
            if (this.storeStudentIds.includes(ids)) {
              lsList = lsList.filter((gg) => gg != ids);
            }
          }
          params.student = params.student.filter((it) =>
            lsList.includes(String(it.id))
          );
          params.delStudentIds = params.delStudentIds?.join(",") ?? "";
        }
        (this.roomSex == 0 || this.roomSex == 1) &&
          (params.roomCharacter = this.roomSex);
        this.$emit("submit", params);
      }
    },
    addStudent() {
      // this.getStudentData({
      //   pageNum: 1,
      //   pageSize: this.pageSize,
      //   ssex: this.addRoom.roomCharacter == 2 ? "" : this.addRoom.roomCharacter,
      //   studentName: this.studentQuery,
      // });
      this.setTableList([]);
      this.$refs.addStudent.show();
    },
  },
  watch: {
    newBuildValue(val) {
      this.newRoomValue = ""
      this.showRoom(val)
    },
    drawer(val) {
      console.log(this.checkBuildInfo);
    },
    visible(val) {
      this.drawer = val;
      if (!val) {
        this.addRoom = { roomNo: "", bedNum: "", roomCharacter: "" };
        this.loading = false;
        this.options = [];
        this.storeStudentIds = []; //  用作记录修改时候存进来的学生id
      } else {
        this.addRoom.roomCharacter = this.addRoom.roomCharacter || this.roomSex;
        this.addRoom.roomCharacter =
          this.addRoom.roomCharacter >= 2 ? "" : this.addRoom.roomCharacter;
      }
    },
    addStudentShow(val) {
      if (!val) {
        this.studentQuery = ""; // 搜索学生内容
        this.pageSize = 10;
        this.current = 1;
      }
    },
    data(val) {
      this.addRoom = {
        ...val,
        // roomCharacter:
        //   val.roomCharacter >= 2 ? "" : val.roomCharacter || this.roomSex,
        student: val.existStudents
          .split(",")
          .filter(Boolean)
          .map((it) => {
            const l = it.split("-");
            return {
              id: l[0],
              title: l[1] + `(${l[2]})`,
            };
          }),
        roomNo: val.roomNo.replace(val.buildingName, ''),
      };
      this.storeStudentIds = val.existStudents
        .split(",")
        .filter(Boolean)
        .map((it) => {
          const l = it.split("-");
          return String(l[0]);
        });
      console.log(
        this.addRoom,
        this.roomSex != 2,
        this.roomSex == 2 && this.addRoom.student && this.addRoom.student.length
      );
    },
  },
  computed: {
    ...studentState(["tableList", "total", ""]),

    ...mapState(["roomSex", "checkBuildInfo", "roomList"]),
    checkedBuildName() {
      const list = this.roomList.filter(
        (it) => it.id == this.checkBuildInfo?.buildingId
      );
      return list.length ? list[0].label : "";
    },
    buildSex() {
      const list = this.roomList.filter(
        (it) => it.id == this.checkBuildInfo?.buildingId
      );
      return list.length ? list[0].buildingCharacter : "";
    },
  },
  mounted() {
    this.data && (this.addRoom = this.data);
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
  },
};
</script>

<style lang="scss" scoped></style>