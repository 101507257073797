<template>
  <el-dialog :close-on-click-modal="false" width="1100px" :visible.sync="addStudentShow" custom-class="other-dialog">
    <div style="padding: 0px" slot="title" class="d-f ai-c jc-sb">
      <div class="c-3 fw-b">
        搜索学生
        <el-input v-model="studentQuery" placeholder="请输入学生信息" style="width: 350px; margin-left: 32px">
          <el-button @click="searchStudent" slot="append">
            <img src="@/assets/search.svg" alt="" />
          </el-button>
        </el-input>
      </div>
    </div>
    <el-table height="500" @selection-change="handleSelectionChange" :data="tableList"
      style="width: 100%; margin-top: 17px">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column type="index" align="center" width="50">
        <template slot-scope="scope">
          {{ (current - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="studentName" label="姓名" width="100">
      </el-table-column>
      <el-table-column prop="studentNo" label="性别" width="50">
        <template slot-scope="scope">
          {{ ["男", "女", "保密"][scope.row.ssex] }}
        </template>
      </el-table-column>
      <el-table-column prop="studentNo" label="学号" width="100">
      </el-table-column>
      <el-table-column prop="identityNo" show-overflow-tooltip label="身份证号">
      </el-table-column>
      <el-table-column prop="enrolYear" label="入学年度" width="80">
      </el-table-column>
      <el-table-column prop="academyName" show-overflow-tooltip label="学院" width="100">
      </el-table-column>
      <el-table-column prop="departmentName" label="系" width="100">
      </el-table-column>
      <el-table-column prop="buildingName" label="楼栋号" width="100">
      </el-table-column>
      <el-table-column prop="roomNo" label="房间号" width="100">
      </el-table-column>
    </el-table>
    <common-page @current-change="currentChange" :current-page="current" :page-size="pageSize" :total="total"
      @size-change="handleSizeChange" background :page-sizes="[15, 30, 45, 60]"
      layout="total, sizes, prev, pager, next, jumper"></common-page>

    <div slot="footer">
      <div class="d-f ai-c jc-c" style="padding-bottom: 8px">
        <el-button style="width: 88px" @click="closeDialog">取消</el-button>
        <el-button style="width: 88px" type="primary" @click="batchAddd">添加</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("room");
const {
  mapState: studentState,
  mapActions: studentAction,
  mapMutations: studentMutation,
} = createNamespacedHelpers("student");
export default {
  props: {
    ssex: String,
    roomSex: String,
  },
  data() {
    return {
      addStudentShow: false,
      studentQuery: "",
      pageSize: 15,
      current: 1,
      batchStudent: [],
    };
  },
  methods: {
    ...studentAction(["getStudentData"]),
    ...studentMutation(["setTableList", "setQuerySearch", "setTotal"]),
    closeDialog() {
      this.addStudentShow = false;
      this.setTableList([]);
      this.setTotal(0)
      this.setQuerySearch({});
    },
    addStudents() {
      this.$emit("addStudents", this.batchStudent);
      this.closeDialog();
    },
    show() {
      this.studentQuery = "";
      this.batchStudent = [];

      this.closeDialog();
      this.addStudentShow = true;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.studentQuery = "";
      this.currentChange(1);
    },
    currentChange(page) {
      //    页码切换
      this.current = page;
      this.getStudentData({
        pageNum: page,
        pageSize: this.pageSize,
        ssex: this.ssex,
        //  this.addRoom.roomCharacter == 2 ? "" : this.addRoom.roomCharacter,
        studentName: this.studentQuery,
      });
    },
    searchStudent() {
      //  搜索学生
      if (this.studentQuery) {
        //
        this.getStudentData({
          pageNum: 1,
          pageSize: this.pageSize,
          ssex: this.ssex,
          // this.addRoom.roomCharacter == 2 ? "" : this.addRoom.roomCharacter,
          complexCondition: this.studentQuery,
        });
      }
    },
    handleSelectionChange(item) {
      // 选中学生
      this.batchStudent = item;
    },
    batchAddd() {
      if (
        this.batchStudent.length &&
        this.batchStudent.some(
          (it) => this.roomSex != 2 && it.ssex != this.roomSex
        )
      ) {
        this.$message.error(
          "性别与宿舍分配不合理！当前楼栋为" +
          ["男生宿舍", "女生宿舍"][this.roomSex]
        );
        return;
      }
      if (
        this.batchStudent.length &&
        this.batchStudent.some((it) => it.buildingName && it.roomNo)
      ) {
        this.$confirm("检测到有学生分配了房间，是否继续强行分配？", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.addStudents();
        });
      } else {
        this.addStudents();
      }

      ///  选中学生
    },
  },
  computed: {
    ...studentState(["tableList", "total", ""]),
  },
};
</script>

<style></style>