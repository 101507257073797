<template>
  <span>
    <el-dialog
      :close-on-click-modal="false"
      width="360px"
      :visible.sync="modifyVisible"
      :before-close="closeModify"
      title="修改"
    >
      <el-input v-model="modifyVal" />
      <div slot="footer">
        <el-button @click="closeModify">取 消</el-button>
        <el-button type="primary" @click="modify">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="type == 'cell'"
      :close-on-click-modal="false"
      width="360px"
      :visible.sync="deleteVisible"
      :before-close="closeDelete"
    >
      <div slot="title" class="d-f ai-c">
        <img src="@/assets/warn.svg" style="margin-right: 8px" alt="" />
        删除确认
      </div>
      你确定要删除所选楼栋/楼层吗
      <div slot="footer">
        <el-button @click="closeDelete">取 消</el-button>
        <el-button type="primary" @click="deleteFunc">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="type == 'room'"
      :close-on-click-modal="false"
      width="360px"
      :visible.sync="deleteVisible"
      :before-close="closeDelete"
    >
      <div slot="title" class="d-f ai-c">
        <img src="@/assets/warn.svg" style="margin-right: 8px" alt="" />
        删除确认
      </div>
      你确定要删除所选房间信息吗
      <div slot="footer">
        <el-button @click="closeDelete">取 消</el-button>
        <el-button type="primary" @click="deleteFunc">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="type == 'floor'"
      :close-on-click-modal="false"
      width="360px"
      :visible.sync="deleteVisible"
      :before-close="closeDelete"
    >
      <div slot="title" class="d-f ai-c">
        <img src="@/assets/warn.svg" style="margin-right: 8px" alt="" />
        删除确认
      </div>
      你确定要删除所选楼层吗？
      <div slot="footer">
        <el-button @click="closeDelete">取 消</el-button>
        <el-button type="primary" @click="deleteFunc">确 定</el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
export default {
  data() {
    return {
      modifyVal: "",
      floorNum: "",
      buildingCharacter: "",
      T: null,
    };
  },
  methods: {
    modify() {
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        if (this.modifyVal) {
          this.closeModify();
          this.$emit("modify", this.modifyVal);
        } else {
          this.$message.error("请填写完整");
        }
      }, 200);
    },
    deleteFunc() {
      this.closeDelete();
      this.$emit("deleteSure");
    },
    closeModify() {
      this.$emit("update:modifyVisible", false);
    },
    closeDelete() {
      this.$emit("update:deleteVisible", false);
    },
  },
  watch: {
    modifyName(val) {
      this.modifyVal = val;
    },
  },
  props: {
    modifyVisible: Boolean,
    modifyName: String,
    deleteVisible: Boolean,
    type: String,
  },
};
</script>

<style>
</style>