<template>
  <el-drawer
    :wrapperClosable="false"
    custom-class="room-add-room"
    :title="title || '添加楼栋/楼层'"
    :visible="drawer"
    :before-close="close"
    :direction="'rtl'"
    :size="350"
  >
    <div class="add-room-body d-f">
      <div class="add-room-content">
        <div class="add-room-title require">楼栋</div>
        <el-input
          v-model="addRoom.buildingName"
          placeholder="请填写楼栋"
          maxlength="10"
          show-word-limit
        />
        <div class="add-room-title require">楼层数量</div>
        <el-input
          :value="addRoom.floorNum"
          @input="(val) => (addRoom.floorNum = Math.abs(val) >>> 0)"
          @blur="
            (e) =>
              (addRoom.floorNum =
                e.target.value >= minAddCell
                  ? Math.abs(e.target.value) >>> 0
                  : minAddCell)
          "
          type="number"
          placeholder="请填写数量"
        />
        <div class="add-room-title require">楼栋男女寝设置</div>
        <el-select
       
        
          v-model="addRoom.buildingCharacter"
          placeholder="选择男女寝属性"
          style="width: 100%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="add-room-submit w-100_ d-f ai-c">
        <el-button type="primary" @click="addRoomFunc">保 存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { getSchoolId } from "../common/store";
export default {
  name: "room-body-add-room",
  data() {
    return {
      minAddCell: 1,
      drawer: false, //  抽屉
      addRoom: {
        buildingName: "",
        floorNum: "", // 楼层
        buildingCharacter: "",
      },
      loading: false,
      options: [
        {
          value: "0",
          label: "男生宿舍",
        },
        {
          value: "1",
          label: "女生宿舍",
        },
        {
          value: "2",
          label: "男女混住",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    addRoomFunc() {
      const warning = {
        buildingName: "请输入楼栋",
        floorNum: "请输入楼层数量",
        buildingCharacter: "请选择寝室类型",
      };
      const list = Object.keys(warning);
      let canIgo = true;
      for (let i = 0; i < list.length; i++) {
        if (!String(this.addRoom[list[i]])) {
          this.$message.error(warning[list[i]]);
          canIgo = false;
          break;
        }
      }

      if (canIgo) {
        this.$emit("update:visible", false);
        this.$emit("submit", {
          ...this.addRoom,
          floorNum: this.addRoom.floorNum + "",
          universityId: getSchoolId(),
        });
      }
    },
    remoteMethod(query) {
      // 远程搜索学生信息
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = [];
        }, 800);
      } else {
        this.options = [];
      }
    },
  },
  watch: {
    visible(val) {
      this.drawer = val;
      const {
        buildingName = "",
        floorNum = "", // 楼层
        buildingCharacter = "",
      } = this.data || {};
      this.addRoom = {
        buildingName,
        floorNum, // 楼层
        buildingCharacter,
      };
      this.minAddCell = this.data.floorNum;
      if (!val) {
        this.addRoom = {
          buildingName: "",
          floorNum: "", // 楼层
          buildingCharacter: "",
        };
      }
    },
    data(val) {
      console.log(this.val);
      this.addRoom = val;
      this.minAddCell = val.floorNum;
    },
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
</style>