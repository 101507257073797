<template>
  <div class="tree-check h-100_ d-f">
    <div @click="openAll" :style="checkedCell == -1 && 'background:#f0f0f0'" class="tree-header c-p d-f jc-sb ai-c fw-b">
      全部
      <i :style="open && 'transform: rotate(180deg);'" class="el-icon-arrow-down"></i>
    </div>
    <div style="flex: 1" class="w-100_ tree-check-box">
      <tree-check-item v-for="(item, index) in roomList" :key="item.id + index" :label="item.label" :idx="item.id"
        :item="item" :list="item.children" :roomSex="item.buildingCharacter">
      </tree-check-item>
    </div>
    <el-button v-if="!isMM && checkedCell == -1" @click="addCells = true" type="primary" class="w-100_">添加楼栋</el-button>
    <el-button v-else-if="!isMM && buttonType == 'build'" @click="addBuildCell" type="primary"
      class="w-100_">添加楼层</el-button>
    <el-button v-else @click="drawer = true" type="primary" class="w-100_">添加房间</el-button>
    <RoomBodyAddCell :visible.sync="showAdd" @submit="addCell"></RoomBodyAddCell>
    <room-body-add-room :visible.sync="drawer" @submit="addRoomFunc" />
    <el-dialog :close-on-click-modal="false" custom-class="room-header-add" title="批量添加楼栋" :visible.sync="addCells"
      width="360px">
      <el-input placeholder="请输入楼栋，多个用“/”隔开，如1号楼/2号楼/3号楼" v-model="batchAdd.buildingNames" />
      <el-input type="number" placeholder="请输入楼层数量" :value="batchAdd.floorNum" min="0"
        @input="(val) => (batchAdd.floorNum = Math.abs(val) >>> 0)" />
      <el-input placeholder="请输入房间数量，例：1-10，15-20（共16间房）" v-model="batchAdd.roomNoInfo" />
      <el-input placeholder="请输入房间床位数量" v-model="batchAdd.bedNum" />
      <el-select style="width: 100%" v-model="batchAdd.roomCharacter">
        <el-option label="男生宿舍" value="0">男生宿舍</el-option>
        <el-option label="女生宿舍" value="1">女生宿舍</el-option>
        <el-option label="男女混住" value="2">男女混住</el-option>
      </el-select>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="addCells = false">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("room");
import TreeCheckItem from "./treeCheckItem.vue";
import RoomBodyAddCell from "./roomBodyAddCell.vue";
import RoomBodyAddRoom from "./roomBodyAddRoom.vue";
export default {
  name: "tree-check",
  data() {
    return {
      activeNames: [],
      open: false,
      showAdd: false,
      addCells: false,
      drawer: false,
      batchAdd: {
        buildingNames: "", // 楼栋
        floorNum: "", //  楼层数量
        roomNoInfo: "", // 房间
        bedNum: "", //  床位
        roomCharacter: "0",
      },
    };
  },
  components: { TreeCheckItem, RoomBodyAddCell, RoomBodyAddRoom },
  methods: {
    ...mapActions(["getRoomList", "batAdd", "getAllData", "addBuild", "addBuildCell", "addRoomData"]),
    ...mapMutations(["setCheckedCell", "setRoomSex"]),
    handleChange(val) {
      // 折叠面板改变事件
      console.log(val);
    },
    sure() {
      // 批量添加确认
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        const { buildingNames, floorNum, roomNoInfo, bedNum } = this.batchAdd;
        if (buildingNames && floorNum && roomNoInfo && bedNum) {
          this.batAdd(this.batchAdd).then(() => {
            this.showAdd = false;
            this.addCells = false
          });
        } else {
          this.$message.error("信息请填写完整");
        }
      }, 200);
    },
    openAll() {
      //  点击全部的操作
      // this.open = !this.open;
      this.setCheckedCell(-1);
      this.getAllData();
      this.setRoomSex("")
      // this.$nextTick(() => {
      //   this.$children.forEach((vm) => {
      //     vm.open = this.open;
      //   });
      // });
    },
    addCell(data) {
      // 新增楼栋
      this.addBuild(data);
    },
    addRoomFunc(data) {
      //  添加房间信息
      console.log(data);
      const params = {
        ...data,
        roomNo: data.roomNo,
        bedNum: data.bedNum,
        addStudentIds: (data.student || []).map((it) => it.id).join(","),
      };
      this.addRoomData(params).then(() => {
        this.drawer = false;
      });
    },
  },
  mounted() {
    this.getRoomList();
    this.setCheckedCell(-1);
  },
  computed: {
    ...mapState({
      roomList: (state) => state.roomList,
      chooseType: (state) => state.chooseType,
      checkedCell: (state) => state.checkedCell,
      mustOpenId: (state) => state.mustOpenId,
      checkBuildInfo: state => state.checkBuildInfo
    }),
    isMM() {
      return this.$store.state.login.isMM;
    },
    buttonType() {
      if (this.checkBuildInfo.floorNo) {
        return 'floor'
      }
      return 'build'
    }
  },
  watch: {
    checkBuildInfo(val) { console.log(val) }
  }
};
</script>

<style lang="scss" scoped>
.tree-check {
  min-width: 212px;
  background: #fff;
  flex-direction: column;
  border: 1px solid #d7d8d9;

  .tree-header {
    line-height: 45px;
    padding: 0 12px 0 16px;
    border-bottom: 1px solid #d7d8d9;
    font-weight: 600;
    color: #262626;

    i {
      transition: all 0.3s;
    }
  }

  .tree-check-box {
    overflow: auto;
  }
}

.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }

  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>