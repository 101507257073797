<template>
  <div class="tree-check-item c-p p-r" :style="'height:' + (open ? itemHeight : 40) + 'px'">
    <div @click="clickHeader" @contextmenu="contextmenu" :style="checkedCell == idx && 'background:rgb(240, 240, 240);'"
      class="title d-f jc-sb ai-c fw-b">
      <span>
        {{ label }}
        <span :class="'fs-12 ' + (checkedCell == idx ? 'c-c' : 'c-9')">{{
          `(${["男生宿舍", "女生宿舍", "男女混合"][item.buildingCharacter]})`
        }}</span>
      </span>
      <img :style="open && 'transform: rotate(180deg);'" class="el-icon-arrow-down" src="@/assets/arr-down.svg" />
    </div>

    <div @click="clickItem(item)" :style="(idx == buildId && idnx + 1 == checkedRoom ? 'background:rgb(240, 240, 240)' : '') + (idnx ? '' : ';margin-top:5px')
      " class="c-p item fw-b d-f ai-c jc-sb" v-for="(item, idnx) in list" :key="item.id">
      <span> {{ item.label }} </span>

      <img v-if="!isMM" @click.stop="list.length == idnx + 1 && deleteCells(idnx + 1)" class="c-p"
        :style="list.length != idnx + 1 ? 'cursor: not-allowed;' : ''" :title="list.length == idnx + 1
          ? '删除该楼层'
          : '请从第' + list.length + '层开始删除'
          " src="@/assets/delete-r.svg" alt="" />
    </div>
    <el-dropdown class="p-a" style="width: 1px; height: 0; top: 40px; left: 50px" trigger="click" @command="command">
      <div ref="drop" style="width: 1px; height: 0; top: 0" @click="(e) => e.preventDefault()"></div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="modify">修改</el-dropdown-item>
        <el-dropdown-item command="delete">删除</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <cell-set :deleteVisible.sync="deleteVisible" type="cell" @deleteSure="deleteFunc" />
    <cell-set :deleteVisible.sync="deleteFloorVisible" type="floor" @deleteSure="deleteFloorFunc"></cell-set>
    <room-body-add-cell title="修改楼栋/楼层" @submit="modify" :visible.sync="modifyVisible" :data="item"></room-body-add-cell>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("room");
import CellSet from "./cellSet.vue";
import RoomBodyAddCell from "./roomBodyAddCell.vue";
const { mapActions: setActions, mapState: setDState } =
  createNamespacedHelpers("setting");
import { getPassWord } from "../common/store";
import md5 from "../common/md5";
export default {
  name: "tree-checke-item",
  components: {
    CellSet,
    RoomBodyAddCell,
  },
  data() {
    return {
      open: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
      deleteFloorVisible: false,
      floorNum: "",
      needConfirmDelete: false,
    };
  },
  props: {
    idx: Number,
    label: String,
    roomSex: String,
    list: {
      type: Array,
      default: () => [],
    },
    item: Object,
  },
  methods: {
    ...mapActions([
      "getCellData",
      "getRoomData",
      "modifyBuild",
      "deleteBuild",
      "deleteCell",
    ]),
    ...mapMutations([
      "setQuerySearch",
      "setSearchValue",
      "setRoomSex",
      "setCheckedRoom",
      "setCheckedCell",
    ]),
    deleteFunc() {
      //   删除
      this.deleteBuild(this.idx).then(() => {
        this.deleteVisible = false;
      });
    },
    modify(val) {
      //  修改
      this.modifyBuild({
        id: this.idx,
        ...val,
      }).then(() => {
        this.modifyVisible = false;
      });
    },
    confirmFunc() {
      return new Promise((res) => {
        this.$prompt("请输入登录密码进行验证！", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "password",
        }).then(({ value }) => {
          if (md5(value) == getPassWord()) {
            res();
          } else {
            this.$message.error("密码错误！");
          }
        });
      });
    },
    deleteFloorFunc() {
      this.deleteCell({
        id: this.idx,
        floorNum: this.floorNum,
      });
    },
    deleteCells(index) {
      if (this.needConfirmDelete) {
        this.confirmFunc().then(() => {
          this.deleteFloorVisible = true;
          this.floorNum = index;
        });
      } else {
        this.deleteFloorVisible = true;
        this.floorNum = index;
      }
    },
    command(item) {
      if (item == "modify") {
        this.modifyName = this.label;
        console.log(this.item);
        this.modifyVisible = true;
      }
      if (item == "delete") {
        if (this.needConfirmDelete) {
          this.confirmFunc().then(() => {
            this.deleteVisible = true;
          });
        } else {
          this.deleteVisible = true;
        }
      }
    },
    clickHeader() {
      this.open = !this.open;
      this.setQuerySearch(1);
      this.setSearchValue("");
      this.setCheckedRoom("");
      this.getCellData(this.idx);
      this.setRoomSex(this.roomSex);
      // this.open &&
      this.getRoomData({
        buildingId: this.idx,
        floorNo: "",
        pageNum: 1,
        id: ""
      });
    },
    clickItem(item) {
      console.log(this.roomSex);
      this.setCheckedCell(this.idx);
      this.setQuerySearch(1);
      this.setSearchValue("");
      this.setRoomSex(this.roomSex);
      this.getRoomData({
        floorNo: item.id + 1,
        buildingId: item.buildingId,
        pageNum: 1,
        id: ""
      });
    },
    contextmenu(e) {
      if (this.isMM) {
        return;
      }
      let event = e || window.event;
      event.preventDefault(); // 阻止默认右击
      this.$refs.drop.click();
    },
  },
  computed: {
    ...mapState(["checkedCell", "checkedRoom", "buildId", "mustOpenId"]),
    ...setDState(["settingBaseinfo"]),
    itemHeight() {
      return this.list.length * 40 + 45;
    },
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
  watch: {
    settingBaseinfo(val) {
      val.forEach((item) => {
        if (item.paramTypeCode == "securitySet") {
          this.needConfirmDelete = item.paramValue == 1;
        }
      });
    },

    mustOpenId(val) {
      if (val == -999) return
      if (val == this.idx) {
        this.open = true
      }
    }
    ,
  },
};
</script>

<style lang="scss" scoped>
.tree-check-item {
  transition: all 0.3s;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);

  .title {
    height: 40px;
    padding: 0 12px 0 16px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);

    i {
      transition: all 0.3s;
    }
  }

  .item {
    height: 40px;
    padding: 0 12px 0 32px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);

    img {
      display: none;
    }

    &:hover {
      background: rgb(240, 240, 240);

      img {
        display: block;
      }
    }
  }

  .item+.item {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }
}
</style>